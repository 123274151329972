import { useEffect, useState } from "react";

const testImages = {
  lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
  lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
  alpha:
    "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
  animation:
    "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
};

const useWebP = () => {
  const [webP, setWebP] = useState(true);

  useEffect(() => {
    const results = Object.keys(testImages).map(
      key =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img.width > 0 && img.height > 0);
          img.onerror = () => resolve(false);
          img.src = `data:image/webp;base64,${testImages[key]}`;
        })
    );

    Promise.all(results).then(data => setWebP(!data.some(r => !r)));
  }, []);

  return webP;
};

export default useWebP;
