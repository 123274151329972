import React, { useCallback, useRef } from "react";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";
import useWebP from "../../hooks/useWebp";
import { getFileParts } from "../../utils/browser";

const ImageWrapper = styled.div`
  position: relative;

  .lazyload-wrapper {
    height: 100%;
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${loadingAnimation} 1s infinite;
`;

const StyledImage = styled.img`
  position: absolute;
  margin: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledPicture = styled.div`
  img {
    margin: 0;
  }
`;

const HairOne = () => {
  const webP = useWebP();
  const extension = webP ? "webp" : "png";
  return (
    <svg
      width="190"
      height="200"
      viewBox="0 0 190 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M31.7053 157.528C49.1618 187.729 77.4386 189.76 95.4701 179.864C113.502 169.968 99.8962 165.589 120.621 158.503C141.346 151.416 152.553 158.044 156.824 136.704C162.092 110.391 103.946 104.78 107.526 87.22C111.106 69.6601 140.575 38.3243 82.593 21.8306C59.066 15.138 6.81758 39.5853 27.6108 74.5787C48.404 109.572 18.753 135.12 31.7053 157.528Z"
        fill="#E1EEF5"
      />
      <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="190" height="200">
        <path
          d="M31.7047 157.528C49.1612 187.729 77.438 189.76 95.4696 179.864C113.501 169.968 125.582 179.993 146.306 172.907C167.031 165.82 152.552 158.044 156.824 136.705C162.091 110.391 162.58 94.6791 166.16 77.1192C169.74 59.5593 140.574 38.3244 82.5924 21.8307C59.0655 15.1382 6.81701 39.5853 27.6102 74.5787C48.4035 109.572 18.7524 135.12 31.7047 157.528Z"
          fill="#E1EEF5"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="25.7871" y="14.053" width="127.991" height="171.521" fill="url(#pattern0)" />
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use
            xlinkHref="#image0"
            transform="translate(-0.00253808) scale(0.000638144 0.00047619)"
          />
        </pattern>
        <image
          key={`hair1-${extension}`}
          id="image0"
          width="1575"
          height="2100"
          xlinkHref={`https://static.wavyformula.com/images/illustration1.${extension}`}
        />
      </defs>
    </svg>
  );
};

const HairTwo = () => {
  const webP = useWebP();
  const extension = webP ? "webp" : "png";
  return (
    <svg
      width="190"
      height="200"
      viewBox="0 -10 190 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M154.579 134.918C145.238 171.837 116.144 182.391 94.2585 177.367C72.3728 172.344 85.3585 163.692 61.4807 162.422C37.603 161.151 27.8065 171.45 16.9646 150.309C3.5958 124.241 62.9954 101.031 54.0057 83.6537C45.0161 66.2767 4.73284 42.1409 60.7187 7.5499C83.4359 -6.48598 145.593 3.63054 134.176 46.5766C122.758 89.5227 161.509 107.525 154.579 134.918Z"
        fill="#E1EEF5"
      />
      <mask id="mask1" maskUnits="userSpaceOnUse" x="0" y="0" width="190" height="200">
        <path
          d="M94.2585 177.367C116.144 182.391 145.238 171.837 154.579 134.918C161.509 107.525 122.758 89.5228 134.176 46.5768C145.593 3.6307 83.4359 -6.48585 60.7187 7.55003C26.6532 28.5975 9.33157 19.7982 3.01486 13.5957C2.99732 13.561 2.97972 13.5265 2.96205 13.4923C-0.557706 6.68866 -1.0498 9.60455 3.01486 13.5957C11.9189 31.2361 3.62206 124.292 16.9646 150.309C27.8065 171.45 33.1564 158.154 46.9375 167.761C60.7187 177.367 72.3728 172.344 94.2585 177.367Z"
          fill="#E1EEF5"
        />
      </mask>
      <g mask="url(#mask1)">
        <rect x="8.6377" y="-9.55994" width="142.566" height="190.088" fill="url(#pattern1)" />
      </g>
      <defs>
        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image1" transform="scale(0.000634921 0.000476191)" />
        </pattern>
        <image
          key={`hair2-${extension}`}
          id="image1"
          width="1575"
          height="2100"
          xlinkHref={`https://static.wavyformula.com/images/illustration2.${extension}`}
        />
      </defs>
    </svg>
  );
};

const HairThree = () => {
  const webP = useWebP();
  const extension = webP ? "webp" : "png";
  return (
    <svg
      width="190"
      height="200"
      viewBox="37 25 190 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M93.6908 51.7907C122.187 23.8782 153.959 30.3175 170.786 46.7511C187.613 63.1847 171.282 63.8341 191.937 77.9993C212.593 92.1645 226.972 88.3018 225.13 113.12C222.859 143.722 157.085 132.063 155.64 152.505C154.196 172.947 177.044 216.507 108.112 216.886C80.1415 217.04 30.0869 174.078 63.7292 141.91C97.3715 109.742 72.5474 72.5011 93.6908 51.7907Z"
        fill="#E1EEF5"
      />
      <mask id="mask2" maskUnits="userSpaceOnUse" x="0" y="0" width="190" height="200">
        <path
          d="M93.6912 51.7907C129.208 33.6327 153.959 30.3175 170.786 46.7511C187.613 63.1847 171.282 63.8341 191.938 77.9993C212.594 92.1645 226.972 88.3017 225.13 113.12C222.859 143.722 197.806 153.346 181.854 166.21C158.823 184.784 177.044 216.507 108.112 216.886C80.1418 217.04 75.991 191.904 63.7295 141.91C51.468 91.9163 5.03406 97.1167 93.6912 51.7907Z"
          fill="#E1EEF5"
        />
      </mask>
      <g mask="url(#mask2)">
        <rect
          width="147.272"
          height="196.136"
          transform="matrix(-1 0 0 1 183.509 41.0249)"
          fill="url(#pattern2)"
        />
      </g>
      <defs>
        <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use
            xlinkHref="#image2"
            transform="translate(0 -0.00057667) scale(0.000634921 0.00047674)"
          />
        </pattern>
        <image
          key={`hair3-${extension}`}
          id="image2"
          width="1575"
          height="2100"
          xlinkHref={`https://static.wavyformula.com/images/illustration3.${extension}`}
        />
      </defs>
    </svg>
  );
};

const HairFour = () => {
  const webP = useWebP();
  const extension = webP ? "webp" : "png";
  return (
    <svg
      width="190"
      height="200"
      viewBox="20 45 190 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M71.2902 218.757C99.2941 246.187 130.517 239.859 147.054 223.709C163.59 207.56 147.541 206.922 167.84 193.001C188.139 179.081 202.269 182.877 200.459 158.487C198.227 128.413 133.589 139.872 132.17 119.783C130.75 99.6939 153.203 56.8857 85.462 56.5136C57.975 56.3625 8.785 98.5821 41.8461 130.194C74.9073 161.807 50.512 198.404 71.2902 218.757Z"
        fill="#E1EEF5"
      />
      <mask id="mask3" maskUnits="userSpaceOnUse" x="0" y="0" width="190" height="200">
        <path
          d="M71.2912 218.757C99.2951 246.187 130.518 239.859 147.054 223.709C163.591 207.56 147.542 206.921 167.841 193.001C188.14 179.08 202.27 182.876 200.46 158.487C198.228 128.413 219.219 103.226 217.8 83.1372C216.381 63.0483 153.204 56.8857 85.463 56.5135C57.9759 56.3625 -8.00925 103.728 25.0519 135.34C58.113 166.952 50.513 198.404 71.2912 218.757Z"
          fill="#E1EEF5"
        />
      </mask>
      <g mask="url(#mask3)">
        <rect
          width="144.561"
          height="192.748"
          transform="matrix(-1 0 0 1 181.294 48.4573)"
          fill="url(#pattern3)"
        />
      </g>
      <defs>
        <pattern id="pattern3" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image3" transform="scale(0.000634921 0.000476191)" />
        </pattern>
        <image
          key={`hair4-${extension}`}
          id="image3"
          width="1575"
          height="2100"
          xlinkHref={`https://static.wavyformula.com/images/illustration4.${extension}`}
        />
      </defs>
    </svg>
  );
};

interface LazyImageProps {
  src: string;
  alt: string;
  once?: boolean;
  className?: string;
}

interface MultiSizeImageProps {
  src: string;
  alt?: string;
  className?: string;
}

const LazyImage = ({ src, alt, className, once = true }: LazyImageProps) => {
  const refPlaceholder = useRef<HTMLDivElement>(null);

  const removePlaceholder = useCallback(() => {
    refPlaceholder.current.remove();
  }, []);

  return (
    <ImageWrapper className={className}>
      <Placeholder ref={refPlaceholder} />
      <LazyLoad once={once}>
        <StyledImage onLoad={removePlaceholder} onError={removePlaceholder} src={src} alt={alt} />
      </LazyLoad>
    </ImageWrapper>
  );
};

const MultiSizePicture = ({ src, alt, className }: MultiSizeImageProps) => {
  const { base, extension } = getFileParts(src);
  const srcSet = `
          ${base}@150w.${extension} 150w,
          ${base}@300w.${extension} 300w,
          ${base}@900w.${extension} 900w,
          ${base}@1200w.${extension} 1200w,
          ${base}@1500w.${extension} 1500w,
          ${base}@1800w.${extension} 1800w,
          ${base}@2100w.${extension} 2100w
        `;
  const webPsrcSet = `
          ${base}@150w.webp 150w,
          ${base}@300w.webp 300w,
          ${base}@900w.webp 900w,
          ${base}@1200w.webp 1200w,
          ${base}@1500w.webp 1500w,
          ${base}@1800w.webp 1800w,
          ${base}@2100w.webp 2100w
        `;

  const contentType = `image/${extension === "png" ? "png" : "jpeg"}`;
  return (
    <picture className={className}>
      <source type="image/webp" srcSet={webPsrcSet} />
      <source type={contentType} srcSet={srcSet} />
      <img src={`${base}@900w.${extension}`} alt={alt} />
    </picture>
  );
};

const MultiSizeImage = ({ src, alt, className }: MultiSizeImageProps) => {
  return (
    <StyledPicture className={className}>
      <MultiSizePicture src={src} alt={alt} />
    </StyledPicture>
  );
};

export { HairOne, HairTwo, HairThree, HairFour, LazyImage, MultiSizeImage, MultiSizePicture };
